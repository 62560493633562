import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
 
const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  list-style: none;
  height: 40px;
  text-decoration: none;
  font-size: 16px;
  &:hover {
    background: #252831;
    border-radius: 8px;
    cursor: pointer;
  }
`;
 
const SidebarLabel = styled.span`
  margin-left: 20px;
`;

const DropdownLink = styled(Link)`
  background: #252831;
  height: 55px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
 
  &:hover {
    
    cursor: pointer;
    color: #ffbb00;
  }
`;
 
const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);
 
  const showSubnav = () => setSubnav(!subnav);
 
  return (
    <>
      <SidebarLink to={item.path}
      onClick={item.subNav && showSubnav} className="mt-3">
        <div>
          {item.icon}
          <SidebarLabel >{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink to={item.path} key={index} >
              {item.icon}
              <SidebarLabel>{item.title}</SidebarLabel>
            </DropdownLink>
          );
        })}
    </>
  );
};
 
export default SubMenu;