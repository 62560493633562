import React from "react";
import Sidebar from "../components/Sidebar";

export const Notifications = () => {
  return (
    <>
    <Sidebar/>
  
    <div className="services">
      <h1>Notifications</h1>
    </div>
    </>
  );
};
 
