import React from "react";
import { Customers } from "./Customers";
import Payments from "./Payments";

const Home = () => {
  
  return (
    <>
      <Customers />
      <Payments/>
      
    </>
  );
};

export default Home;
