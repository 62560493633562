const Status = [
    {
        id: "1",

        title: "Active",
    },
    {
        id: "0",
        title: "Pending",
      
    },
    {
        id: "2",
        title: "Expired",
    },
    {
        id: "3",
        title: "Terminated",
    },
];

export default Status;